const preRoute = '/cabinet'

export const routeNames = {
    preRoute: preRoute,
    personalData: preRoute + '/personal-data/',
    myOrders: preRoute + '/my-orders/',
    notifications: preRoute + '/notifications/',
    appeals: preRoute + '/appeals/',
    appeal: preRoute + '/appeal/',
    appealCrete: preRoute + '/crete-an-appeal/',
    favorites: preRoute + '/favorites/',
}